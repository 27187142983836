export const getStudentFullName = (student, charsToSlice = 0) => {
   if (!student) {
      return "No name";
   }
   const full_name = student?.last_name ? student?.first_name + " " + student?.last_name : student?.first_name;
   if (!full_name) {
      return "No name";
   }
   if (charsToSlice) {
      return full_name.length > charsToSlice ? full_name.slice(0, charsToSlice) + "..." : full_name;
   } else {
      return full_name;
   }
};