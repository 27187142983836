import axios from "axios";
import { notification } from "antd";
import { store } from "redux-store/store";
import { clearUser } from "redux-store/slices/auth";

export const baseURL = process.env.REACT_APP_BASE_URL;
const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 16000,
});
// Config
const TOKEN_PAYLOAD_KEY = "authorization";
const AUTH_TOKEN = "access_token";
const AUTH_TOKEN_TYPE = "Bearer";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem(AUTH_TOKEN);
    // debugger;
    if (access_token) {
      config.headers[TOKEN_PAYLOAD_KEY] = AUTH_TOKEN_TYPE + " " + access_token;
    }
    if (config.method === "get") {
      config.params = { ...config.params };
    }
    if (config.responseType === 'arraybuffer') {
      config.responseType = 'arraybuffer';
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    let notificationParam = {
      message: error.response.data.message,
    };
    // Remove token and redirect
    if (error.response.status === 403) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('refresh_token');
      window.location.href = '/sign-in';
      store.dispatch(clearUser());
    }
    if (error.config.url == "user/token/") {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      if (error.config.url === 'user/token/refresh/') {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/sign-in';
        store.dispatch(clearUser());
      } else {
        const refresh = localStorage.getItem('refresh_token');
        return service.post('user/token/refresh/', { refresh }).then(response => {
          localStorage.setItem('access_token', response.access);
          return service(error.config);
        });
      }

    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (error.response.status === 500) {
      notificationParam.message = error.response.data.message;
    }
    if (typeof notificationParam.message == "string") {
      notification.error(notificationParam);
    }
    return Promise.reject(error);
  }
);

export default service;
