import React from "react";

const EmptyChatList = () => {
  return (
    <div style={{ padding: "10px" }} className="empty-chat-list">
      <h2>No chats found!</h2>

    </div>
  );
};

export default EmptyChatList;
