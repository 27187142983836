import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = ({ onChange }) => {
  const handleRecaptchaChange = (value) => {
    // Pass the reCAPTCHA response value to the parent component
    onChange(value);
  };

  return (
    <ReCAPTCHA
      sitekey={"6LesTjspAAAAAACQO0q3G1cdw-3khCim_Pkj8j9h"}
      onChange={handleRecaptchaChange}
    />
  );
};

export default Recaptcha;
