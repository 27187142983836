import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { majorSubjectsService } from "srevices/major-subjects";

const initialState = {
  loading: false,
  dataList: [],
  error: "",
  meta: {},
};

export const fetchSubject = createAsyncThunk(
  "subject/fetchSubject",
  (params = {}) => {
    return majorSubjectsService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);

const subjectSlice = createSlice({
  name: "subject",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSubject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubject.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.dataList = payload.results;
      state.meta = payload.meta;
      state.error = "";
    });
    builder.addCase(fetchSubject.rejected, (state, action) => {
      state.loading = false;
      state.dataList = [];
      state.error = action.error.message;
    });
  },
});

export default subjectSlice.reducer;
