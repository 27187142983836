import request from "./request";

const universityService = {
  login: (data) => request.post("user/token/", data),
  get: (params) => request.get("university/", { params }),
  getReviews: (params) => request.get("university/review/", { params }),
  getOne: (id) => request.get("university/" + id + "/"),
  update: (id, data) => request.patch("university/" + id + "/", data),
  delete: (id) => request.delete("university/" + id + "/"),
  add: (data) => request.post("university/", data),
};

export default universityService;
