import { Avatar, notification } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AvatarStyle } from './components/chatsList';
import { makeFirst } from 'utils/makeFirst';

// Create a new context for the WebSocket connection
const SocketContext = createContext();

// Custom hook to access the socket context
export const useSocket = () => useContext(SocketContext);

// Socket provider component
export const SocketProvider = ({ children }) => {
   const pathname = window.location.href;
   const [notificationSocket, setNotificationSocket] = useState(null);
   const [notificationCount, setNotificationCount] = useState(0);
   const [chatSocket, setChatSocket] = useState(null);
   const [chats, setChats] = useState([]);
   const [currentChat, setCurrentChat] = useState({ loading: true });
   const [typing, setTyping] = useState(false);
   const [messages, setMessages] = useState([]);
   const token = localStorage.getItem('access_token');
   const user = useSelector((state) => state.auth.user);
   const createWebSocket = (url) => {
      const socket = new WebSocket(url);
      return socket;
   };
   const connectToSocket = (url, setter) => {
      const socket = createWebSocket(url);
      setter(socket);
   };

   const connectToNotificationSocket = () => {
      if (!notificationSocket) {
         const websocketUrl = `wss://api.eduance.org/ws/notification/?token=${token}`;
         connectToSocket(websocketUrl, setNotificationSocket);
      }
   };

   const connectToChatSocket = (chatId) => {
      if (chatSocket) {
         chatSocket.close();
      }
      const websocketUrl = `wss://api.eduance.org/ws/chat/${chatId}/?token=${token}`;
      connectToSocket(websocketUrl, setChatSocket);
   };
   const closeChatSocket = () => {
      setCurrentChat({ loading: true, id: "", unread_count: 0 });
   };

   const sendEvent = (message) => {
      if (chatSocket?.readyState === 1) {
         chatSocket.send(JSON.stringify(message));
      } else {
         setTimeout(() => sendEvent(message), 500);
      }
   };
   const sendMessage = (messageInputValue) => {
      if (!Boolean(messageInputValue)) {
         return null;
      }
      const message = {
         type: "message",
         body: messageInputValue,
         group_id: currentChat?.id,
         to_user_id: currentChat.user?.id
      };
      sendEvent(message);
      sendStopTyping(currentChat?.id);
   };
   const sendFile = (fileId) => {
      if (!Boolean(fileId)) {
         return null;
      }
      const message = {
         type: "message",
         body: null,
         file: fileId,
         group_id: currentChat?.id,
         to_user_id: currentChat.user?.id,
         file_type: null
      };
      sendEvent(message);
      sendStopTyping(currentChat?.id);
   };
   const sendTyping = (id) => {
      const message = {
         type: "typing",
         group_id: id
      };
      sendEvent(message);
   };
   const sendStopTyping = (id) => {
      const message = {
         type: "typing_stop",
         group_id: id,
      };
      sendEvent(message);
   };
   const readMessages = (messageId) => {
      if (messageId) {
         const message = { type: "read", message_id: messageId, from_user_id: user?.id };
         sendEvent(message);
         setChats(chats => chats.map(chat => {
            if (chat.id == currentChat.id) {
               return { ...chat, unread_count: 0 };
            } else {
               return chat;
            }
         }));
      }
   };

   useEffect(() => {
      if (notificationSocket) {
         notificationSocket.onopen = function (event) {
            notificationSocket.send(JSON.stringify({ type: 'handshake' }));
         };
         notificationSocket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            if (data.type === 'group_created') {
               if (pathname.includes('support-chat')) {
                  setNotificationCount(0);
               } else {
                  setNotificationCount(notificationCount => notificationCount + 1);
               }
               notification.info({
                  message: 'New Chat',
                  description: <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                     {data.sender_avatar ? <Avatar src={data.sender_avatar?.url} /> : <Avatar style={AvatarStyle} >{String(data.sender_first_name)?.charAt(0)}</Avatar>}
                     <div> {data.sender_first_name + ' ' + data.sender_last_name}</div>
                  </div>
               });
               setChats(chats => [{ ...data, user: { id: data.sender_id, first_name: data.sender_first_name, last_name: data.sender_last_name, avatar: data.sender_avatar } }, ...chats,]);
            }
            if (data.type === 'message') {
               if (data.group_id != currentChat.id) {
                  if (pathname.includes('support-chat')) {
                     setNotificationCount(0);
                  } else {
                     setNotificationCount(notificationCount => notificationCount + 1);
                  }
                  setChats(chats => makeFirst(data.group_id, "id", chats.map(chat => {
                     if (chat.id === data.group_id) {
                        return {
                           ...chat,
                           unread_count: chat.unread_count + 1,
                           last_message: data
                        };
                     } else {
                        return chat;
                     }
                  }))
                  );
                  notification.info({
                     message: 'New message',
                     description: <div style={{ display: 'flex', gap: '5px', alignItems: 'center', marginLeft: "-30px" }}>
                        {data.created_by?.avatar ? <Avatar src={data.created_by.avatar?.url} /> : <Avatar style={AvatarStyle} >{String(data.created_by?.first_name)?.charAt(0)}</Avatar>}
                        <div>
                           <div> {data.created_by?.first_name + ' ' + data.created_by?.last_name}</div>
                           <p style={{ paddingLeft: '5px' }}>{data.body}</p>
                        </div>
                     </div>
                  });
               }
            };
         };
         return () => {
            if (notificationSocket) {
               notificationSocket.onmessage = null;
            }
         };
      }
   }, [notificationSocket, currentChat, pathname]);

   useEffect(() => {
      if (currentChat?.id) {
         connectToChatSocket(currentChat.id);
      } else {
         chatSocket?.close();
      }
   }, [currentChat?.id]);
   useEffect(() => {
      if (chatSocket) {
         chatSocket.onopen = function (event) {
            chatSocket.send(JSON.stringify({ type: 'handshake', group_id: currentChat.id }));
         };
         chatSocket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            if (data.type === 'message') {
               setChats(chats => makeFirst(data.group_id, "id", chats.map(c => {
                  if (c.id === data.group_id) {
                     return {
                        ...c, last_message: data
                     };
                  } else {
                     return c;
                  }
               })));
               readMessages(data.id);
               setMessages(cc => [...cc, data]);
            }
            if (data.type === 'typing') {
               if (data.user_id == currentChat.user.id) {
                  setTyping(data);
               }
            }
            if (data.type === 'typing_stop') {
               if (data.user_id == currentChat.user.id) {
                  setTyping(false);
               }
            }
            if (data.type === "handshake") {
               readMessages(currentChat.last_message?.id);
            }
         };
      }
   }, [chatSocket]);
   useEffect(() => {
      connectToNotificationSocket();
   }, []);
   return (
      <SocketContext.Provider
         value={{
            notificationSocket,
            connectToNotificationSocket,
            chatSocket,
            notificationCount,
            connectToChatSocket,
            messages,
            typing,
            sendMessage,
            sendStopTyping,
            sendTyping,
            chats,
            currentChat,
            setCurrentChat,
            setMessages,
            setChats,
            setNotificationCount,
            closeChatSocket,
            sendFile
         }}
      >
         {children}
      </SocketContext.Provider>
   );
};

