import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setSubjectService } from "srevices/major-subjects";

const initialState = {
  loading: false,
  dataList: [],
  error: "",
  meta: {},
};

export const fetchUniversitySubject = createAsyncThunk(
  "subject/fetchUniversitySubject",
  (params = {}) => {
    return setSubjectService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);

const universitySubjectSlice = createSlice({
  name: "universitySubjects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUniversitySubject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUniversitySubject.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.dataList = payload.results;
      state.meta = payload.meta;
      state.error = "";
    });
    builder.addCase(fetchUniversitySubject.rejected, (state, action) => {
      state.loading = false;
      state.dataList = [];
      state.error = action.error.message;
    });
  },
});

export default universitySubjectSlice.reducer;
