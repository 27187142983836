import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sportActivitiesService } from "srevices/sport-activities";

const initialState = {
  loading: false,
  dataList: [],
  error: "",
  meta: {},
};

export const fetchActivity = createAsyncThunk(
  "activity/fetchActivity",
  (params = {}) => {
    return sportActivitiesService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);

const activitySlice = createSlice({
  name: "activity",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchActivity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActivity.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.dataList = payload.results;
      state.meta = payload.meta;
      state.error = "";
    });
    builder.addCase(fetchActivity.rejected, (state, action) => {
      state.loading = false;
      state.dataList = [];
      state.error = action.error.message;
    });
  },
});

export default activitySlice.reducer;
