import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import universityLocationService from "srevices/university-locations";

const initialState = {
  loading: false,
  dataList: [],
  error: "",
  meta: {},
};

export const fetchUniversityLocations = createAsyncThunk(
  "university/fetchUniversityLocations",
  (params = {}) => {
    return universityLocationService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);

const universityLocationsSlice = createSlice({
  name: "universityLocations",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUniversityLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUniversityLocations.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.dataList = payload.results;
      state.meta = payload.meta;
      state.error = "";
    });
    builder.addCase(fetchUniversityLocations.rejected, (state, action) => {
      state.loading = false;
      state.dataList = [];
      state.error = action.error.message;
    });
  },
});

export default universityLocationsSlice.reducer;
