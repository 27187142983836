import React, { useState } from "react";
import { Button, Form, message, notification } from "antd";
import InputPassword from "components/form/InputPassword";
import UniversalInput from "components/form/UniversalInput";
import Recaptcha from "components/recaptcha";
import authService from "srevices/auth";
import { setUserData } from "redux-store/slices/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [captcha, setCaptcha] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    if (!captcha) {
      notification.error({ message: "Recaptcha Verification Required", description: "Invalid Recaptcha. Please retry!" });
    } else {
      authService
        .login(values)
        .then(({ data }) => {
          localStorage.setItem("access_token", data?.token?.access);
          localStorage.setItem("refresh_token", data?.token?.refresh);
          dispatch(setUserData(data.user));
          navigate("/dashboard");
        })
        .catch((error) => {
          notification.error({ message: "Invalid Credentials", description: "Email or Password does not match!" });
          console.log(error);
        });
    }
  };
  return (
    <Form
      autoComplete="false"
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <UniversalInput rules={[{ required: true, message: "Email is required" }]} type="email" placeholder="Email" name="username" />
      <InputPassword rules={[{ required: true, message: "Password is required" }]} placeholder="Password" name="password" />
      <Recaptcha onChange={setCaptcha} />
      <Button htmlType="submit" type="primary" danger size="large">
        Sign in
      </Button>
    </Form>
  );
};

export default LoginForm;
