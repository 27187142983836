import React from 'react';
import {
   Sidebar,
   // Search,
   ConversationList,
   Conversation,
   Avatar,
} from "@chatscope/chat-ui-kit-react";
import EmptyChatList from "./empty-chat-list";
import { COLORS } from 'constants/ChartConstant';
import SearchInput from 'components/form/SearchInput';
import Loading from 'components/shared-components/Loading';
import { Pagination } from 'antd';
import { getStudentFullName } from 'utils/getStudentFullName';

export const AvatarStyle = { backgroundColor: COLORS[Math.floor(Math.random() * 6)], display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 700, color: "white" };

const ChatList = ({ setCurrentChat, currentChat, chats, setMessages, fetchChats, loading, setPagination, pagination }) => {
   return (
      <Sidebar position="left" scrollable={true}>
         <ConversationList>
            <SearchInput handleChange={search => {
               fetchChats({ search, ...pagination });
            }} placeholder="Search..." />
            {loading ? <Loading /> : !chats?.length ? (
               <EmptyChatList />
            ) : (
               <>
                  {chats.map((item) => (
                     <Conversation
                        key={item.id}
                        name={getStudentFullName(item.user)}
                        lastSenderName={item.last_message?.created_by?.first_name}
                        info={item.last_message.body}
                        unreadCnt={item.unread_count}
                        unreadDot={!Boolean(item.support_user)}
                        active={item.id === currentChat.id}
                        onClick={() => {
                           setMessages([]);
                           setCurrentChat({ ...item, loading: true });
                        }}
                     >
                        <Avatar src={item.user.avatar ? item.user.avatar?.url : 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/768px-User-avatar.svg.png'} />
                     </Conversation>
                  ))}
               </>)
            }
            {loading ? <></> : <Pagination
               onChange={(page, pageSize) => {
                  fetchChats({ page, pageSize });
               }}
               current={pagination.page}
               total={pagination.total}
               pageSize={pagination.pageSize}
               showSizeChanger={true}
               className="mt-4"
            />}
         </ConversationList>
      </Sidebar >
   );
};

export default ChatList;