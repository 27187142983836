import React, { Suspense, useEffect, useState } from "react";
import { Avatar, Breadcrumb, Layout, notification } from "antd";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import LayoutHeader from "components/header";
import Sidebar from "components/sider";
import Loading from "components/shared-components/Loading";
import { useSocket } from "views/support/socketContext";
import Utils from "utils";
const { Content } = Layout;

const customLinks = [
  {
    text: "Contact us",
    url: "contact-us",
    customUrl: "/inquiries",
    state: { activeTab: 1 }
  },
  {
    text: "Review",
    url: "review",
    customUrl: "/inquiries",
    state: { activeTab: 2 }
  },
  {
    text: "Report",
    url: "report",
    customUrl: "/inquiries",
    state: { activeTab: 3 }
  },
  {
    text: "Feedback",
    url: "feedback",
    customUrl: "/inquiries",
    state: { activeTab: 4 }
  },
];

const AppLayout = () => {
  const { pathname, state } = useLocation();
  const urls = pathname.split('/').splice(1);
  const { notificationCount } = useSocket();
  const breadcrumbItems = () => {
    if (urls.length < 2) {
      return [];
    }
    const getText = (pathOrder, text) => {
      if (!state?.breadcrumb) {
        return text;
      }
      if (state.breadcrumb.pathOrder !== pathOrder) {
        return text;
      }
      return state.breadcrumb.text;
    };
    const getCustomLink = (url) => {
      const customLink = customLinks.find(cl => cl.url === url);
      if (customLink) {
        return <NavLink style={{ color: "#007EDA" }} to={customLink.customUrl} state={customLink.state} >{customLink.text}</NavLink>;
      } else {
        return false;
      }
    };


    return urls.map((url, i) => {
      if (i === 0) {
        return { title: <NavLink style={{ color: urls.length === 1 ? "#007EDA" : 'unset' }} to={'/' + url} >{Utils.capitalizeFirstLetter(url)}</NavLink> };
      } else if (i == 1) {
        return {
          title: urls.length > 2 ?
            getCustomLink(urls[1]) ? getCustomLink(urls[1]) : <NavLink style={{ color: urls.length === 2 ? "#007EDA" : 'unset' }} to={'/' + urls[0] + '/' + url} >{getText(2, Utils.capitalizeFirstLetter(url))}</NavLink> : <div style={{ color: urls.length === 2 ? "#007EDA" : 'unset' }} to={'/' + urls[0] + '/' + url} >{getText(2, Utils.capitalizeFirstLetter(url))}</div>
        };
      } else if (i == 2) {
        return { title: urls.length > 3 ? <NavLink style={{ color: urls.length === 3 ? "#007EDA" : 'unset' }} to={'/' + urls[0] + '/' + url} >{getText(3, Utils.capitalizeFirstLetter(url))}</NavLink> : <div style={{ color: urls.length === 3 ? "#007EDA" : 'unset' }} to={'/' + urls[0] + '/' + url} >{getText(2, Utils.capitalizeFirstLetter(url))}</div> };
      }
    });
  };
  return (
    <Layout hasSider>
      <Sidebar />
      <Layout>
        <LayoutHeader notificationCount={notificationCount} />
        <Content>
          <Breadcrumb
            items={[
              ...breadcrumbItems()

            ]}
          />
          <Suspense fallback={<Loading cover="page" />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AppLayout;
