import request from "./request";

const universityLocationService = {
  get: (params) => request.get("university/campus-location/", { params }),
  add: (data) => request.post("university/campus-location/", data),
  update: (id, data) => request.put(`university/campus-location/${id}/`, data),
  delete: (id) => request.delete(`university/campus-location/${id}/`),
};

export default universityLocationService;
