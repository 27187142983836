import React from "react";
import { Image } from "antd";
import LoginForm from "./components/login-form";
import AdminPlatform from "./components/admin-platform";

const SignIn = () => {
  return (
    <div className="login-wrapper">
      <div className="login-form">
        <div className="auth-page-logo">
          <Image
            src="/assets/images/logo.png"
            width={"max-content"}
            height={"100%"}
            preview={false}
          />
        </div>
        <div className="title">Sign in to Admin account to continue</div>
        <LoginForm />
      </div>
      <div className="admin-platform">
        <div className="title mt-5">
          Eduance Admin <br /> Platform
        </div>
        <p className="pl-5 mt-2 mb-4">
          Experience why over 2+ million students choose <br /> Our platform
        </p>
        <AdminPlatform />
      </div>
    </div>
  );
};

export default SignIn;
