import auth from "./slices/auth";
import activity from "./slices/sport-activities";
import subject from "./slices/major-subject";
import universities from "./slices/universities";
import universityLocations from "./slices/universities-locations";
import universitySubjects from "./slices/universities-subject";
import universityActivity from "./slices/universities-activity";

const rootReducer = {
  auth,
  activity,
  universities,
  universityLocations,
  subject,
  universitySubjects,
  universityActivity,
};

export default rootReducer;
