import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import universityService from "srevices/university";

const initialState = {
  loading: false,
  dataList: [],
  error: "",
  meta: {},
};

export const fetchUniversities = createAsyncThunk(
  "activity/fetchUniversities",
  (params = {}) => {
    return universityService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);

const universitySlice = createSlice({
  name: "university",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUniversities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUniversities.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.dataList = payload.results;
      state.meta = payload.meta;
      state.error = "";
    });
    builder.addCase(fetchUniversities.rejected, (state, action) => {
      state.loading = false;
      state.dataList = [];
      state.error = action.error.message;
    });
  },
});

export default universitySlice.reducer;
