import { Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Spin } from "antd";
import { AdminRoutes } from "router";
import SignIn from "views/auth/sign-in";
import AppLayout from "layout";
import { ProtectedRoute } from "router/protected-route";
import ErrorOne from "views/errors/error-page-1";
import "./App.css";
function App() {
  return (
    <Router>
      <Routes>
        <Route index path="/sign-in" element={<SignIn />} />
        <Route
          path=""
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to="dashboard" />} />
          {AdminRoutes.map(({ path, component: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<Spin />}>
              <ErrorOne />
            </Suspense>
          }
        />
      </Routes>
      <script
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD0krIIvuFtj9MNnKpUwOsZqDquf4Vv4WU&libraries=places&callback=YOUR_CALLBACK_NAME`}
      ></script>
    </Router>
  );
}

export default App;
