import React, { useEffect } from 'react';
import lottie from 'lottie-web'; // Import lottie library
import loaderJson from './loader.json';
const Loader = () => {
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: document.getElementById('loader'), // Provide a container element
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loaderJson, // Insert your JSON data here
    });

    return () => anim.destroy(); // Cleanup on unmount
  }, []);

  // return <div id="loader" />;
  return <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
    <div style={{ maxWidth: "800px", maxHeight: "500px" }} id="loader" />
  </div>;
};

export default Loader;

