import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "assets/scss/index.scss";
import "assets/scss/globals.css";
import "assets/scss/light-theme.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Provider } from "react-redux";
import { MainContextProvider } from "context/main";
import { store, persistor } from "redux-store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Spin } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SocketProvider } from "views/support/socketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<Spin />} persistor={persistor}>
        <GoogleOAuthProvider clientId="995959365540-tsust6k8m5u0ptsut4okr53ohjrfof5c.apps.googleusercontent.com">
          <SocketProvider >
            <MainContextProvider>
              <App />
            </MainContextProvider>
          </SocketProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>
);
