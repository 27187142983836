// ** React Imports
import { lazy } from "react";

export const AdminRoutes = [
  {
    path: "dashboard",
    component: lazy(() => import("views/dashboard")),
  },
  {
    path: "applications",
    component: lazy(() => import("views/applications")),
  },
  {
    path: "university",
    component: lazy(() => import("views/university")),
  },
  {
    path: "university/add",
    component: lazy(() => import("views/university/add")),
  },
  {
    path: "reviews",
    component: lazy(() => import("views/reviews")),
  },
  {
    path: "majors",
    component: lazy(() => import("views/major")),
  },
  {
    path: "sport-activity",
    component: lazy(() => import("views/sport-activity")),
  },
  {
    path: "social-media",
    component: lazy(() => import("views/social-media")),
  },
  {
    path: "academic-years",
    component: lazy(() => import("views/academic-years")),
  },

  {
    path: "orders",
    component: lazy(() => import("views/orders")),
  },
  {
    path: "schedule-meeting",
    component: lazy(() => import("views/calendar")),
  },
  {
    path: "support-chat",
    component: lazy(() => import("views/support")),
  },
  {
    path: "students",
    component: lazy(() => import("views/student")),
  },
  {
    path: "student/add",
    component: lazy(() => import("views/student/add")),
  },
  {
    path: "students/:id",
    component: lazy(() => import("views/student/profile")),
  },
  {
    path: "scholarships",
    component: lazy(() => import("views/scholarships")),
  },
  {
    path: "scholarships/add",
    component: lazy(() => import("views/scholarships/add")),
  },
  {
    path: "orders/:id",
    component: lazy(() => import("views/orders/id")),
  },
  {
    path: "cv-templates",
    component: lazy(() => import("views/cv-templates")),
  },
  {
    path: "account-payable",
    component: lazy(() => import("views/account-payable")),
  },
  {
    path: "account-receivable",
    component: lazy(() => import("views/account-receivable")),
  },
  {
    path: "inquiries",
    component: lazy(() => import("views/inquiries")),
  },
  {
    path: "inquiries/contact-us/:id",
    component: lazy(() => import("views/inquiries/contact-detail")),
  },
  {
    path: "inquiries/review/:id",
    component: lazy(() => import("views/inquiries/review-detail")),
  },
  {
    path: "inquiries/report/:id",
    component: lazy(() => import("views/inquiries/reports-detail")),
  },
  {
    path: "inquiries/feedback/:id",
    component: lazy(() => import("views/inquiries/feedback-detail")),
  },
  {
    path: "content",
    component: lazy(() => import("views/content")),
  },
  {
    path: "gallery",
    component: lazy(() => import("views/gallery")),
  },
  {
    path: "countries",
    component: lazy(() => import("views/countries")),
  },
];
