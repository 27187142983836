import request from "./request";

const userService = {
   get: (params) => request.get("user/", { params }),
   getProfile: () => request.get("user/profile/"),
   getOne: (id) => request.get("user/" + id),
   profileUpdate: (id, data) => request.patch("user/profile/", data),
   delete: (id) => request.delete("user/" + id),
   add: (data) => request.post("user/", data),
   update: (id, data) => request.patch(`user/${id}/`, data),
};

export default userService;
