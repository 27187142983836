import React from "react";
import { Image, Layout, Menu } from "antd";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AccountPayableIcon,
  AccountReceivableIcon,
  ApplicationIcon,
  CVIcon,
  CalendarIcon,
  ChatIcon,
  ContentIcon,
  CountriesIcon,
  DashboardIcon,
  InquiriesIcon,
  MajorIcon,
  OrderIcon,
  ScholarshipIcon,
  SportActivitiesIcon,
  StudentIcon,
  UniversityIcon,
} from "assets/svg";
const { Sider } = Layout;

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const items = [
    { type: "group", label: "General" },
    {
      key: "dashboard",
      icon: <DashboardIcon />,
      label: <Link to="/dashboard">My Dashboard</Link>,
    },
    {
      key: "schedule-meeting",
      icon: <CalendarIcon />,
      label: <Link to="/schedule-meeting">Schedule Meeting</Link>,
    },
    {
      key: "support-chat",
      icon: <ChatIcon />,
      label: <Link to="/support-chat">Live Web Chat</Link>,
    },
    { type: "group", label: "Student Profile" },
    {
      key: "students",
      icon: <StudentIcon />,
      label: <Link to="/students">Students</Link>,
    },
    {
      key: "applications",
      icon: <ApplicationIcon />,
      label: <Link to="/applications">Applications</Link>,
    },

    { type: "group", label: "University Profile" },
    {
      key: "university",
      icon: <UniversityIcon />,
      label: <Link to="/university">Universities</Link>,
    },
    {
      key: "majors",
      icon: <MajorIcon />,
      label: <Link to="/majors">Majors & Programs</Link>,
    },
    {
      key: "sport-activity",
      icon: <SportActivitiesIcon />,
      label: <Link to="/sport-activity">Sport Activity</Link>,
    },
    {
      key: "social-media",
      icon: <CountriesIcon />,
      label: <Link to="/social-media">Social Media</Link>,
    },
    {
      key: "academic-years",
      icon: <CalendarIcon />,
      label: <Link to="/academic-years">Academic Years</Link>,
    },
    {
      key: "scholarships",
      icon: <ScholarshipIcon />,
      label: <Link to="/scholarships">Scholarships</Link>,
    },
    // {
    //   key: 3,
    //   icon: <CommentOutlined />,
    //   label: <Link to="/reviews">Reviews</Link>,
    // },
    { type: "group", label: "Services" },
    {
      key: "orders",
      icon: <OrderIcon />,
      label: <Link to="/orders">Orders</Link>,
    },
    {
      key: "cv-templates",
      icon: <CVIcon />,
      label: <Link to="/cv-templates">CV templates</Link>,
    },
    { type: "group", label: "Accounting" },
    {
      key: "account-payable",
      icon: <AccountPayableIcon />,
      label: <Link to="/account-payable">Account Payable</Link>,
    },
    {
      key: "account-receivable",
      icon: <AccountReceivableIcon />,
      label: <Link to="/account-receivable">Account Receivable</Link>,
    },
    { type: "group", label: "Inquiry" },
    {
      key: "inquiries",
      icon: <InquiriesIcon />,
      label: <Link to="/inquiries">Inquiries</Link>,
    },
    {
      key: "content",
      icon: <ContentIcon />,
      label: <Link to="/content">Content</Link>,
    },
    { type: "group", label: "Settings" },
    {
      key: "gallery",
      icon: <AccountReceivableIcon />,
      label: <Link to="/gallery">Gallery</Link>,
    },
    {
      key: "countries",
      icon: <CountriesIcon />,
      label: <Link to="/countries">Countries</Link>,
    },
  ];
  return (
    <Sider className="sidebar">
      <div className="logo-vertical">
        <Image
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          src="/assets/images/logo-white.png"
          alt="logo"
          width={165}
          height={50}
          preview={false}
        />
      </div>
      <Scrollbars
        autoHeight
        autoHeightMin={"87dvh"}
        autoHeightMax={"87dvh"}
        autoHide
      >
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[
            items.find((item) => pathname.includes(item.key))?.key,
          ]}
          items={items}
        />
      </Scrollbars>
    </Sider>
  );
};

export default Sidebar;
