import React from "react";
import { Form, Input } from "antd";

const UniversalInput = ({
  label,
  name,
  rules,
  restField,
  suffix,
  placeholder,
  className,
  size = "default",
  type = "text",
}) => {
  return (
    <Form.Item {...restField} label={label} name={name} rules={rules}>
      <Input
        type={type}
        size={size}
        suffix={suffix}
        placeholder={placeholder}
        className={className}
      />
    </Form.Item>
  );
};

export default UniversalInput;
