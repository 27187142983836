import { Col, Row } from "antd";
import AvatarStatus from "components/shared-components/AvatarStatus";
export const employementList = [
  {
    img: "/assets/images/login-1.svg",
    title: "Full Access to University Database",
    duration: "No hidden charges, No credit card required",
  },
  {
    img: "/assets/images/login-2.svg",
    title: "Student Portal",
    duration: "Get access instantly, no downloads required",
  },
  {
    img: "/assets/images/login-3.svg",
    title: "Full Access to CV/Essay Database",
    duration: "Enjoy the Free Trial with your company data",
  },
  {
    img: "/assets/images/login-4.svg",
    title: "Premium Profile Badge",
    duration: "Access all features of the world's #1 business software!",
  },
  {
    img: "/assets/images/login-5.svg",
    title: "Application Timeline Tracking",
    duration: "Monitoring application status and progress in real-time",
  },
  {
    img: "/assets/images/login-6.svg",
    title: "Schedule Video Meetings",
    duration: "Coordinating and setting up video calls",
  },
  {
    img: "/assets/images/login-7.svg",
    title: "Personal Student Success Advisor",
    duration: "Offering personalized academic guidance and support",
  },
];

const AdminPlatform = () => (
  <div className="">
    <Row>
      <Col sm={24} md={22}>
        {employementList.map((elm, i) => {
          return (
            <div
              className={`${i === employementList.length - 1 ? "" : "mb-3"}`}
              key={`eduction-${i}`}
            >
              <AvatarStatus
                shape={"square"}
                src={elm.img}
                name={elm.title}
                subTitle={elm.duration}
                className="why-eduance"
              />
            </div>
          );
        })}
      </Col>
    </Row>
  </div>
);

export default AdminPlatform;
