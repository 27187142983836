import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Avatar, Badge, Dropdown, Layout } from "antd";
import { LogoutOutlined, WechatOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import userService from "srevices/user";
import { setUserData } from "redux-store/slices/auth";
import { ChatIcon } from "assets/svg";
import { getStudentFullName } from "utils/getStudentFullName";

const { Header } = Layout;

const LayoutHeader = ({ notificationCount = 0 }) => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const userMenuItems = [
    {
      key: 1,
      icon: <LogoutOutlined />,
      label: `Logout`,
      onClick: () => {
        localStorage.removeItem("access_token");
        dispatch(setUserData(null));
        window.location.href = '/sign-in';
      }
    },
  ];
  const getProfile = async () => {
    const { data } = await userService.getProfile();
    const user = {
      ...data
    };
    dispatch(setUserData(user));
  };
  useEffect(() => {
    if (!user) {
      getProfile();
    }
  }, [user]);

  return (
    <Header className="cusom-header">
      <div className="logo"></div>
      <div className="content">
        <Badge size="small" count={notificationCount}>
          <div className="link">
            <Link to="/support-chat" style={{ display: "flex", alignItems: "center", gap: "3px" }}> Support Chat <ChatIcon /></Link>

          </div>
        </Badge>
        <Dropdown menu={{ items: userMenuItems }} placement="bottomLeft">
          <div className="user-box">
            <div className="data-box">
              <div className="username">{getStudentFullName(user)}</div>
              <div className="position">Administrator</div>
            </div>
            <Avatar src={user?.avatar?.url} size="large" />
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default LayoutHeader;
