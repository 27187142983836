import request from "./request";

export const majorSubjectsService = {
  get: (params) => request.get("university/subject/", { params }),
  getById: (id, params) => request.get(`university/subject/${id}/`, { params }),
  create: (data) => request.post("university/subject/", data),
  update: (id, data) =>
    request.put(`university/subject/${id}/`, data),
  delete: (id) => request.delete(`university/subject/${id}/`),
};

export const setSubjectService = {
  get: (params) => request.get("university/set-subject/", { params }),
  getById: (id, params) =>
    request.get(`university/set-subject/${id}/`, { params }),
  create: (data) => request.post("university/set-subject/", data),
  update: (id, params) =>
    request.put(`university/set-subject/${id}/`, {}, { params }),
  delete: (id) => request.delete(`university/set-subject/${id}/`),
};
