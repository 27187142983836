import request from "./request";

export const sportActivitiesService = {
  get: (params) => request.get("university/sport-activity/", { params }),
  getById: (id, params) =>
    request.get(`university/sport-activity/${id}/`, { params }),
  create: (data) => request.post("university/sport-activity/", data),
  update: (id, data) =>
    request.put(`university/sport-activity/${id}/`, data),
  delete: (id) => request.delete(`university/sport-activity/${id}/`),
};

export const setActivityService = {
  get: (params) => request.get("university/set-sport-activity/", { params }),
  getById: (id, params) =>
    request.get(`university/set-sport-activity/${id}/`, { params }),
  create: (data) => request.post("university/set-sport-activity/", data),
  update: (id, params) =>
    request.put(`university/set-sport-activity/${id}/`, {}, { params }),
  delete: (id) => request.delete(`university/set-sport-activity/${id}/`),
};
